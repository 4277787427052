// Custom style for Nord
.hide, .hidden{
    display: none !important;
}
.img-nf{
    max-height: 20px;
}
body[data-sidebar="dark"] .vertical-menu {
    background: #2e004a !important;
}
body[data-sidebar="dark"] .navbar-brand-box{
    background: #2e004a !important;
}
body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a{
    background: #725a9e !important;
}
body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul ul{
    background: #522270 !important;
}
.display-inherit{
    display: inherit !important;
}
.display-block{
    display: block !important;
}
.display-inline{
    display: inline !important;
}
.display-inline-block{
    display: inline-block !important;
}
.avatar-md span{
    font-size: 24px;
}
.text-right{
    text-align: right !important;
}
.text-left{
    text-align: left !important;
}
.text-center{
    text-align: center !important;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.mt-60 {
    margin-top: 60px !important;
}
.mt-80 {
    margin-top: 80px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-25 {
    margin-bottom: 25px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}
.mb-60 {
    margin-bottom: 60px !important;
}
.mb-80 {
    margin-bottom: 80px !important;
}

.ml-0 {
    margin-left: 0px !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.ml-25 {
    margin-left: 25px !important;
}
.ml-30 {
    margin-left: 30px !important;
}
.ml-40 {
    margin-left: 40px !important;
}
.ml-50 {
    margin-left: 50px !important;
}
.ml-60 {
    margin-left: 60px !important;
}
.ml-80 {
    margin-left: 80px !important;
}

.mr-0 {
    margin-right: 0px !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-15 {
    margin-right: 15px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.mr-25 {
    margin-right: 25px !important;
}
.mr-30 {
    margin-right: 30px !important;
}
.mr-40 {
    margin-right: 40px !important;
}
.mr-50 {
    margin-right: 50px !important;
}
.mr-60 {
    margin-right: 60px !important;
}
.mr-80 {
    margin-right: 80px !important;
}

.pt-0 {
    padding-top: 0px !important;
}
.pt-5 {
    padding-top: 5px !important;
}
.pt-10 {
    padding-top: 10px !important;
}
.pt-15 {
    padding-top: 15px !important;
}
.pt-20 {
    padding-top: 20px !important;
}
.pt-25 {
    padding-top: 25px !important;
}
.pt-30 {
    padding-top: 30px !important;
}
.pt-40 {
    padding-top: 40px !important;
}
.pt-50 {
    padding-top: 50px !important;
}
.pt-60 {
    padding-top: 60px !important;
}
.pt-80 {
    padding-top: 80px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}
.pb-5 {
    padding-bottom: 5px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
}
.pb-15 {
    padding-bottom: 15px !important;
}
.pb-20 {
    padding-bottom: 20px !important;
}
.pb-25 {
    padding-bottom: 25px !important;
}
.pb-30 {
    padding-bottom: 30px !important;
}
.pb-40 {
    padding-bottom: 40px !important;
}
.pb-50 {
    padding-bottom: 50px !important;
}
.pb-60 {
    padding-bottom: 60px !important;
}
.pb-80 {
    padding-bottom: 80px !important;
}
.float-left{
    float: left !important;
}
.float-right{
    float: right !important;
}

.centered{
    display: block;
    margin: auto;
}
.mw-500{
    max-width: 500px;
}
.mw-800{
    max-width: 800px;
}

.mini-stats-wid .mini-stat-icon{
    min-width: 48px;
}
.text-block{
    display: block;
}
.label-box{
    border: 1px solid #ced4da;
    padding: 5px 0.75rem;
    border-radius: 0.25rem;
    background-color: #eee;
    min-height: 36px;
}
.mlb-title{
    display: inline-block;
    margin-top: 6px;
    float: left;
    margin-right: 16px;
    font-size: 16px;
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
    border: 1px solid #eee;
}
.btn-datepicker{
    display: inline-block;
}
.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #740dbf;
}
.img-table{
    max-height: 46px;
    display: inline-block;
    float: left;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 4px;
}
.btn-datepicker input{
    min-width: 250px !important;
    background-color: #fff;
    text-align: left;
    background-image: url("../../../images/icon/date.png");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: right;
    background-position-x: 220px;
}
.btn-datepicker .form-control:disabled,
.btn-datepicker .form-control[readonly]{
    color: #750dbf;
    background-color: #fff;
}
.btn-datepicker input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #750dbf;
    opacity: 1; /* Firefox */
}
  
.btn-datepicker input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #750dbf;
}
  
.btn-datepicker input::-ms-input-placeholder { /* Microsoft Edge */
    color: #750dbf;
}

.form-control:disabled, .form-control[readonly]{
    background-color: #fff;
}
.do-ol{
    padding-left: 10px;
}
.do-ol ol{
    
}
.do-ol li{
    padding-left: 5px;
    margin-bottom: 10px;
}

.wizard > .content{
    padding: inherit !important;
    margin-top: 32px;
}
.forbidden{
    cursor: not-allowed;
}
.nested-box{
    border: 1px solid #ddd;
    display: grid;
    background-color: #eee;
}
.nested-box td{
    padding: 16px;
}
.forbidden-block{
    
}
.forbidden-block img{
    width: 100%;
    max-width: 450px;
}
.bd-status button{
    width: 100%;
}
.bd-status form{
    padding: 16px;
    border-radius: 8px;
    background-color: #eee;
}